import React from "react"
import styled from "styled-components"
import MetaHeader from "../components/MetaHeader"
import { Container, Typography, Button } from "@material-ui/core"
import _c from "../uiConfig/colors"
import { navigate } from "gatsby"

export default function NothingFoundScreen() {
  return (
    <MetaHeader>
      <StyledContainer>
        <Typography
          style={{ fontFamily: "Anton", textAlign: "center" }}
          variant="h4"
          component="h1"
          color={"primary"}
          gutterBottom
        >
          404! Nothing found.
        </Typography>
        <StyledButton
          onClick={e => {
            e.preventDefault()
            navigate("/", { replace: true })
          }}
          variant={"contained"}
          color={"primary"}
        >
          Home
        </StyledButton>
      </StyledContainer>
    </MetaHeader>
  )
}

const StyledContainer = styled(Container)`
  min-height: 100vh;
  max-height: 100vh;
  max-width: 100vh;
  max-width: 100vh;
  background-color: ${_c.screen_background};
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
`
const StyledButton = styled(Button)`
  color: #fff;
  padding: 7px 14px;
  border-radius: 5px;
  margin-top: 20%;
  width: 20%;
  text-transform: none;
  font-family: Work Sans;
`
